<template>
  <div class="event-financials">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" :is-promoter="true"/>
        </div>
        <tab-tote
          :tabs="navTabs"
          :value="navTabSelected"
          @click="onClickNavTab"
        />
        <div class="divider"></div>
        <router-view :event="event" />
      </div>
    </div>
  </div>
</template>

<script>
import TableTote from "@/components/TableTote.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TabTote from "../../../shared/components/TabTote.vue";

export default {
  name: "financials",
  components: {
    TableTote,
    Breadcrumbs,
    TabTote
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Financials"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Financials", null]
      ],
      event: {},
      eventInfo: [],
      eventObj: {},
      events: [],
      tableData: {},
      navTabs: ["Transactions", "Commissions / Collections" ]
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("commissions")) {
        return 1;
      } else if (path.endsWith("transactions")) {
        return 0;
      }

      return 0;
    }
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;

      if (index === 1 && !path.endsWith("commissions")) {
        this.$router.push(`/financials/${this.url}/commissions`);
      } else if (index === 0 && !path.endsWith("financials")) {
        this.$router.push(`/financials/${this.url}/transactions`);
      }
    }
  },
  mounted() {
    this.$store.commit("setTitle", "Affiliate Financials");

    this.$axios.get("/event/" + this.url).then(response => {
      const event = response.data;
      this.event = event;
      this.$store.commit("setSubtitle", event.name);
    });
  }
};
</script>
